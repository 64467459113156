import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

import media from '../utils/media'

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  }

  html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat';

    ${media.lessThan('sm')`
      overflow-x: hidden; 
    `}
  }
`
