// custom typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { wrapRootElement as wrap } from './root-wrapper'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer')
  }
}

export const wrapRootElement = wrap
