import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from './src/config/theme'
import Layout from './src/components/templates/Layout'
import { GlobalStyle } from './src/styles/global-style'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>{element}</Layout>
  </ThemeProvider>
)
