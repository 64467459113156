import styled from 'styled-components'

const MainContainer = styled.div`
  max-width: 650px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
`

export default MainContainer
