import React from 'react'
import styled from 'styled-components'

import Footer from '../../organisms/Footer'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
`
const Layout = ({ children }) => (
  <MainContainer>
    <main>{children}</main>
    <Footer />
  </MainContainer>
)

export default Layout
