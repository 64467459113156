import * as React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import MainContainer from '../../atoms/MainContainer'

const StyledFooter = styled.footer`
  padding-top: 24px;
  padding-bottom: 24px;
  font-family: 'Merriweather';
  font-size: 16px;
  color: ${({ theme }) => get(theme, 'textColor')};
`

const Footer = () => (
  <MainContainer>
    <StyledFooter>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href='https://www.gatsbyjs.com'>Gatsby</a>
    </StyledFooter>
  </MainContainer>
)

export default Footer
