const theme = {
  black: '#000',
  white: '#FFF',
  textColor: '#2e353f',
  quoteColor: '#4f5969',
  titleColor: '#005c99',
  errorColor: '#c6241d',
  popupBackground: '#3a2a2d'
}

export default theme
